import { json, redirect } from '@remix-run/node'
import { Form, useActionData, useTransition } from '@remix-run/react'
import { Button } from '~/components/base/button'
import { TextInput } from '~/components/base/inputs'
import { BaseHeader } from '~/components/base/menu/baseHeader'
import { requireAdmin, login } from '~/utils/session.server'

export const action = async ({ request }) => {
  try {
    const body = await request.formData()
    const email = body.get('email')

    const response = new Response()
    await login({ email, request, response })

    return json(
      { message: `Your login link has been sent to "${email}"` },
      { headers: response.headers },
    )
  } catch (error) {
    console.error(error)
    return json({ error: `Error: ${error.message}` })
  }
}

export const loader = async ({ request }) => {
  try {
    const response = new Response()
    const user = await requireAdmin(request, response)
    if (user) {
      return redirect('/a', { headers: response.headers })
    }
  } catch (error) {
    return null
  }
}

export default function LoginPage() {
  const transition = useTransition()
  const isSubmitting = transition.state === 'submitting'

  const actionData = useActionData()
  const error = actionData?.error
  const message = actionData?.message
  const isFormDisabled = isSubmitting || !!message

  return (
    <div className="relative w-full flex flex-col">
      <BaseHeader />

      <div className="w-full max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-16">
        <main className="w-full p-4 flex-grow">
          <Form
            method="post"
            className="p-5 bg-white rounded shadow-md mb-16 max-w-screen-md"
          >
            <h2 className="text-lg font-semibold">🔒 Kalir Login</h2>

            <div className="mt-4">
              <TextInput
                name="email"
                type="email"
                required={true}
                placeholder="Email"
                autoComplete="off"
                disabled={isFormDisabled}
              />
            </div>

            <div className="mt-8">
              <Button
                type="submit"
                disabled={isFormDisabled}
                isLoading={isSubmitting}
              >
                {isSubmitting ? 'Logging in...' : 'Log in'}
              </Button>
            </div>

            <div className="mt-2 text-sm">
              {error ? (
                <span className="text-red-500">{error}</span>
              ) : message ? (
                <span className="text-green-800">{message}</span>
              ) : (
                <>&nbsp;</>
              )}
            </div>
          </Form>
        </main>
      </div>
    </div>
  )
}
